import request from '@/utils/request'
// import {getToken} from "@/utils/auth";
//会员列表
export function getMenberList(params) {
    return request({
      url: '/admin/member/list',
      method: 'GET',
      params
    })
  }
//会员详情
export function getMemberInfo(params) {
    return request({
        url: '/admin/member/info/'+params.id,
        method: 'GET',
        params
    })
}
//用户消费记录表
export function getMemberConumeList(params) {
    return request({
        url: '/admin/member/consume/list/'+params.id,
        method: 'GET',
        params
    })
}

//会员权益列表
export function getVipIndex(params) {
    return request({
        url: '/admin/vip/index',
        method: 'GET',
        params
    })
}
//会员权益详情
export function getVipInfo(params) {
    return request({
        url: '/admin/vip/info/'+params.id,
        method: 'GET',
        params
    })
}
//优惠券下拉
export function getCouponList(params) {
    return request({
        url: '/admin/coupon-select',
        method: 'GET',
        params
    })
}
//新增会员权益
export function putVipAdd(data) {
    return request({
        url: '/admin/vip/add',
        method: 'put',
        data
    })
}
//修改会员权益
export function postVipEdit(data) {
    return request({
        url: '/admin/vip/edit/'+data.id,
        method: 'post',
        data
    })
}
<script>
import { getCouponList,putVipAdd,postVipEdit} from "@/newApi/member";
export default {
  name: "drawerDetail",
  props: {
  },
  data() {
    return {
      fullscreenLoading: false,
      type:'add',//新增或者修改
      id:'',//修改id
      info: {
        vip_name: "", //会员等级
        vip_level: "", //等级名称
        consume: "", //所需消费
        award_list: [
          {
            amount: "", //积分数量
            coupon_id: "", //优惠id
            type: "", //类型
            id: "", //接口返回什么传什么,新增传0
            vip_id: "", //会员id,接口返回什么传什么,新增传0
          },
        ],
      }, //表单数据
      list: [
        { value: 1, label: "积分" },
        { value: 2, label: "优惠券" },
      ], //下拉框数据
      couponList: [], //优惠券选择列表
      rules: {
        vip_name: [
            { required: true, message: '请输入会员名称', trigger: 'blur' },
          ],
          vip_level: [
            { required: true, message: '请输入等级名称', trigger: 'blur' },
          ],
          consume: [
            { required: true, message: '请输入消费金额', trigger: 'blur' },
          ],
        },
    };
  },

  //生命周期
  created() {
    this.getSelect();
  },
  methods: {
    changeSelect(row) {
      if (row == 2) {
        this.getSelect();
      }
    },
    getSelect() {
      getCouponList().then((res) => {
        this.couponList = res.data;
      });
    },
    //增加
    handleAdd(){
      //双向绑定
      if(this.type == 'add'){
        this.info.award_list.push({
        amount: "", //积分数量
        coupon_id: "", //优惠id
        type: "", //类型
        id: 0, //接口返回什么传什么,新增传0
      })
      }else{
        this.info.award_list.push({
          amount: "", //积分数量
          coupon_id: "", //优惠id
          type: "", //类型
          vip_id:0,
          id:0,
        })
      }
    },
    //删除
    handleDel(index){
      this.info.award_list.splice(index,1)
      console.log(this.info.award_list[index])
    },
    //取消
    closeDrawer() {
      let drawers = false;
      this.$emit("closeDrawer", drawers);
    },
    typeAddOrEdit(){
      let list = this.info.award_list
      list.forEach(item=>{
        if(item.type == 1){
          item.coupon_id = 0
        }else{
          item.amount = 0
        }
      })
    },
    //保存编辑
    _putVipAdd(data){
      putVipAdd(data).then(res=>{
        if(res.code == 200){
          this.$message({
            message:'保存成功',
            type: "success",
          });
          this.closeDrawer();
          this.$emit("getList");
        }else{
          this.$message({
            message:'请将内容填写完整',
            type: "error",
          });
        }
      })
    },
    //保存修改
    _postVipEdit(data){
      postVipEdit(data).then(res=>{
        if(res.code == 200){
          this.$message({
            message:'修改成功',
            type: "success",
          });
          this.closeDrawer();
          this.$emit("getList");
        }else{
          this.$message({
            message:'请将内容填写完整',
            type: "error",
          });
        }
        
      })
    },
    //确定保存
    handleConfirm(row){
      this.typeAddOrEdit()
      console.log(this.info)
      if(this.type == 'add'){
        this._putVipAdd(this.info)
      }else{
        this.info.id = this.id
        this._postVipEdit(this.info)
      }
    }
  },
};
</script>

<template>
  <div class="gg-container" v-loading.fullscreen.lock="fullscreenLoading">
    <div style="margin-bottom:20px;">
      <span style="font-size:25px;border-bottom:3px solid #1684FC;"
        >新增会员权益</span
      >
    </div>
    <div class="content">
      <el-form ref="form" :rules="rules" :model="info" label-width="80px" size="mini">
        <el-form-item label="会员名称" prop="vip_name">
          <el-input
            v-model="info.vip_name"
            size="mini"
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="等级名称" prop="vip_level">
          <el-input
            v-model="info.vip_level"
            size="mini"
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="所需消费" prop="consume">
          <el-input v-model="info.consume" size="mini" class="input"></el-input>
        </el-form-item>
        <el-form-item
          label="奖品类型"
          v-for="(item, index) in info.award_list"
          :key="index"
        >
          <el-select
            filterable
            clearable
            v-model="item.type"
            placeholder="请选择"
            style="margin-right:15px"
            @change="changeSelect"
          >
            <el-option
              v-for="item in list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="item.coupon_id"
            filterable
            clearable
            placeholder="请选择优惠券"
            v-if="item.type == 2"
          >
            <el-option
              v-for="item in couponList"
              :key="item.coupon_id"
              :label="item.coupon_name"
              :value="item.coupon_id"
            >
            </el-option>
          </el-select>
          <el-input
            placeholder="请输入积分数量"
            v-model="item.amount"
            size="mini"
            class="input"
            v-else
          />
          <span style="margin-left:15px;color:#1684FC" @click="handleDel(index)"
            >删除</span
          >
        </el-form-item>
        <div>
          <i
            class="el-icon-circle-plus-outline"
            style="color:#1684FC"
            @click="handleAdd"
          ></i>
          <span style="color:#1684FC" @click="handleAdd">添加</span>
        </div>
      </el-form>
    </div>
    <div class="footer">
      <el-button size="small" @click="closeDrawer">取 消</el-button>
      <el-button type="primary" size="small" @click="handleConfirm('ruleForm')"
        >{{type == 'add' ? '确 定':'修 改'}}</el-button
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
.input {
  width: 200px;
}
.content {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}
.footer {
  position: absolute;
  bottom: 100px;
  right: 100px;
}
</style>
